import { useLocalStorage } from '@grandstand-web/bally-web-shared/src/hooks/useLocalStorage'
import styled from 'styled-components'

type NextIdDisplayProps = {
  nextId?: string
}

const NextIdDisplayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  z-index: 50;
  padding: 5px;
  width: 300px;
  height: 30px;
  background: orange;
  color: black;
  opacity: 0.8;
  text-align: center;
`

const NextIdDisplay = ({ nextId }: NextIdDisplayProps) => {
  const [shouldShowNextId] = useLocalStorage('debug_shouldShowNextId', false)

  return <>{shouldShowNextId && <NextIdDisplayWrapper>next_id: {nextId}</NextIdDisplayWrapper>}</>
}

export default NextIdDisplay
