import styled, { css } from 'styled-components'

export type HeadingFontSize = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'
export type SubheadingFontSize = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'
export type WinFontSize = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'
export type ParagraphFontSize = '0' | '1' | '2' | '3' | '4'
export type LinkFontSize = '0' | '1' | '2' | '3' | '4' | '5'
export type ButtonFontSize = 'min' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
export type CaptionFontSize = 'min' | 'xs' | 'sm' | 'md' | 'lg'
export type MetadataFontSize = 'min' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
export type PrimaryFontSize = 'min' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
export type SecondaryFontSize = 'min' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
// TODO(tgrow): update usage/instances to make fontSize a required prop (including ButtonLabel)
export type ButtonLabelProps = {
  fontSize?: ButtonFontSize
  tabletFontSize?: ButtonFontSize
  mobileFontSize?: ButtonFontSize
}
/* Mixin Definitions */

export const heading = css<{
  fontSize: HeadingFontSize
  tabletFontSize?: HeadingFontSize
  mobileFontSize?: HeadingFontSize
}>`
  font-size: ${({ fontSize }) => `var(--heading-${fontSize}-size)`};
  line-height: var(--heading-line-height);
  font-weight: var(--heading-font-weight);
  font-family: var(--heading-font-fam);
  @media (max-width: 768px) {
    font-size: ${(props) => `var(--heading-${props.tabletFontSize ?? props.fontSize}-size)`};
  }
  @media (max-width: 375px) {
    font-size: ${(props) => `var(--heading-${props.mobileFontSize ?? props.tabletFontSize ?? props.fontSize}-size)`};
  }
`

export const subheading = css<{
  fontSize: SubheadingFontSize
  tabletFontSize?: SubheadingFontSize
  mobileFontSize?: SubheadingFontSize
}>`
  font-size: ${({ fontSize }) => `var(--sub-heading-${fontSize}-size)`};
  line-height: var(--sub-heading-line-height);
  font-weight: var(--sub-heading-font-weight);
  font-family: var(--sub-heading-font-fam);

  @media (max-width: 768px) {
    font-size: ${(props) => `var(--sub-heading-${props.tabletFontSize ?? props.fontSize}-size)`};
  }
  @media (max-width: 375px) {
    font-size: ${(props) =>
      `var(--sub-heading-${props.mobileFontSize ?? props.tabletFontSize ?? props.fontSize}-size)`};
  }
`

export const win = css<{
  fontSize: WinFontSize
  tabletFontSize?: WinFontSize
  mobileFontSize?: WinFontSize
}>`
  font-size: ${({ fontSize }) => `var(--win-${fontSize}-size)`};
  line-height: var(--win-line-height);
  font-weight: var(--win-font-weight);
  font-family: var(--win-font-fam);

  @media (max-width: 768px) {
    font-size: ${(props) => `var(--win-${props.tabletFontSize ?? props.fontSize}-size)`};
  }
  @media (max-width: 375px) {
    font-size: ${(props) => `var(--win-${props.mobileFontSize ?? props.tabletFontSize ?? props.fontSize}-size)`};
  }
`

export const paragraph = css<{
  fontSize: ParagraphFontSize
  tabletFontSize?: ParagraphFontSize
  mobileFontSize?: ParagraphFontSize
}>`
  font-size: ${({ fontSize }) => `var(--paragraph-${fontSize}-size)`};
  line-height: var(--paragraph-line-height);
  font-weight: var(--paragraph-font-weight);
  font-family: var(--paragraph-font-fam);

  @media (max-width: 768px) {
    font-size: ${(props) => `var(--paragraph-${props.tabletFontSize ?? props.fontSize}-size)`};
  }
  @media (max-width: 375px) {
    font-size: ${(props) => `var(--paragraph-${props.mobileFontSize ?? props.tabletFontSize ?? props.fontSize}-size)`};
  }
`

export const link = css<{
  fontSize: LinkFontSize
  tabletFontSize?: LinkFontSize
  mobileFontSize?: LinkFontSize
}>`
  font-size: ${({ fontSize }) => `var(--link-${fontSize}-size)`};
  line-height: var(--link-line-height);
  font-weight: var(--link-font-weight);
  font-family: var(--link-font-fam);

  @media (max-width: 768px) {
    font-size: ${(props) => `var(--link-${props.tabletFontSize ?? props.fontSize}-size)`};
  }
  @media (max-width: 375px) {
    font-size: ${(props) => `var(--link-${props.mobileFontSize ?? props.tabletFontSize ?? props.fontSize}-size)`};
  }
`

export const caption = css<{
  fontSize: CaptionFontSize
  tabletFontSize?: CaptionFontSize
  mobileFontSize?: CaptionFontSize
}>`
  font-size: ${({ fontSize }) => `var(--caption-${fontSize}-size)`};
  line-height: var(--caption-line-height);
  font-weight: var(--caption-font-weight);
  font-family: var(--caption-font-fam);

  @media (max-width: 768px) {
    font-size: ${(props) => `var(--caption-${props.tabletFontSize ?? props.fontSize}-size)`};
  }
  @media (max-width: 375px) {
    font-size: ${(props) => `var(--caption-${props.mobileFontSize ?? props.tabletFontSize ?? props.fontSize}-size)`};
  }
`

export const metadata = css<{
  fontSize: MetadataFontSize
  tabletFontSize?: MetadataFontSize
  mobileFontSize?: MetadataFontSize
}>`
  font-size: ${({ fontSize }) => `var(--metadata-${fontSize}-size)`};
  line-height: var(--metadata-line-height);
  font-weight: var(--metadata-font-weight);
  font-family: var(--metadata-font-fam);

  @media (max-width: 768px) {
    font-size: ${(props) => `var(--metadata-${props.tabletFontSize ?? props.fontSize}-size)`};
  }
  @media (max-width: 375px) {
    font-size: ${(props) => `var(--metadata-${props.mobileFontSize ?? props.tabletFontSize ?? props.fontSize}-size)`};
  }
`

export const button = css<ButtonLabelProps>`
  // TODO(tgrow): Once usage/instances updated - remove if check
  font-size: ${(props) => `var(--button-${props.fontSize ?? 'xl'}-size)`};
  line-height: var(--button-line-height);
  font-weight: var(--button-font-weight);
  font-family: var(--button-font-fam);

  @media (max-width: 768px) {
    font-size: ${(props) => `var(--button-${props.tabletFontSize ?? props.fontSize}-size)`};
  }
  @media (max-width: 375px) {
    font-size: ${(props) => `var(--button-${props.mobileFontSize ?? props.tabletFontSize ?? props.fontSize}-size)`};
  }
`

export const labelPrimary = css<{
  fontSize: PrimaryFontSize
  tabletFontSize?: PrimaryFontSize
  mobileFontSize?: PrimaryFontSize
}>`
  font-size: ${({ fontSize }) => `var(--label-primary-${fontSize}-size)`};
  line-height: var(--label-primary-line-height);
  font-weight: var(--label-primary-font-weight);
  font-family: var(--label-primary-font-fam);

  @media (max-width: 768px) {
    font-size: ${(props) => `var(--label-primary-${props.tabletFontSize ?? props.fontSize}-size)`};
  }
  @media (max-width: 375px) {
    font-size: ${(props) =>
      `var(--label-primary-${props.mobileFontSize ?? props.tabletFontSize ?? props.fontSize}-size)`};
  }
`

export const labelSecondary = css<{
  fontSize: SecondaryFontSize
  tabletFontSize?: SecondaryFontSize
  mobileFontSize?: SecondaryFontSize
}>`
  font-size: ${({ fontSize }) => `var(--label-secondary-${fontSize}-size)`};
  line-height: var(--label-secondary-line-height);
  font-weight: var(--label-secondary-font-weight);
  font-family: var(--label-secondary-font-fam);

  @media (max-width: 768px) {
    font-size: ${(props) => `var(--label-secondary-${props.tabletFontSize ?? props.fontSize}-size)`};
  }
  @media (max-width: 375px) {
    font-size: ${(props) =>
      `var(--label-secondary-${props.mobileFontSize ?? props.tabletFontSize ?? props.fontSize}-size)`};
  }
`

/* Font Styled Components */

export const HeadingLabel = styled.div`
  ${heading}
`

export const SubheadingLabel = styled.div`
  ${subheading}
`

export const WinLabel = styled.div`
  ${win}
`

export const ParagraphLabel = styled.div`
  ${paragraph}
`

export const LinkLabel = styled.div`
  ${link}
`

export const CaptionLabel = styled.div`
  ${caption}
`

export const ButtonLabel = styled.div`
  ${button}
`

export const MetaDataLabel = styled.div`
  ${metadata}
`

export const PrimaryLabel = styled.div`
  ${labelPrimary}
`

export const SecondaryLabel = styled.div`
  ${labelSecondary}
`
