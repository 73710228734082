import { forwardRef, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import LoadingSpinner from './LoadingSpinner'

/*
Use of this is intended to be via direct class setting.
Without any class manipulation, it shows a black background and spinner
With "disable-loading-spinner" class set, it won't show the loading spinner
Remove the default "video-not-loaded" class to turn off the spinner
Set the "buffering" class to turn on the manual buffering spinner
*/

type TOuterClasses = 'buffering' | 'disable-loading-spinner' | 'video-not-loaded'

const LoadingSpinnerOverLay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
  top: 0;
  left: 0;
  display: none;
  &.buffering {
    > .background {
      opacity: 0.5;
    }
    display: block;
  }
  &.video-not-loaded {
    display: block;
  }
  &.disable-loading-spinner {
    left: 999em;
    display: none;
  }
`

const LoadingSpinnerBackground = styled.div<{ isBuffering?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
`

const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface VideoLoadingOrBufferingProps {
  disableLoadingSpinner?: boolean
}

const VideoLoadingOrBuffering = forwardRef(function Blubba(
  { disableLoadingSpinner = false }: VideoLoadingOrBufferingProps,
  ref
) {
  const stateRef = useRef(null)

  const removeClass = (cname: TOuterClasses) => {
    const element = stateRef?.current ? (stateRef?.current as HTMLElement) : null
    if (element) {
      element.classList.remove(cname)
    }
  }

  const addClass = (cname: TOuterClasses) => {
    const element = stateRef?.current ? (stateRef?.current as HTMLElement) : null
    if (element) {
      element.classList.add(cname)
    }
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        setIsBuffering(value: boolean) {
          if (value) {
            addClass('buffering')
          } else {
            removeClass('buffering')
          }
        },
        declareVideoLoaded() {
          removeClass('video-not-loaded')
        },
      }
    },
    []
  )

  const classes: Array<TOuterClasses> = ['video-not-loaded']

  if (disableLoadingSpinner) {
    classes.push('disable-loading-spinner')
  }

  return (
    <LoadingSpinnerOverLay className={classes.join(' ')} ref={stateRef}>
      <LoadingSpinnerBackground className="background" />
      <LoadingSpinnerWrapper>
        <LoadingSpinner />
      </LoadingSpinnerWrapper>
    </LoadingSpinnerOverLay>
  )
})

export default VideoLoadingOrBuffering
