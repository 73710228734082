import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'
import { GrandstandIcon } from './GrandstandIcon'
export type ButtonSize = 'min' | 'xs' | 'sm' | 'md' | 'lg'
export type ButtonStyle = 'primary' | 'alt' | 'on-image' | 'live'

const paddingForSize = (size: ButtonSize) => {
  switch (size) {
    case 'min':
      return '9px'
    case 'xs':
      return '10px'
    case 'sm':
      return '16px'
    case 'md':
      return '20px 24px'
    case 'lg':
      return '32px 34px'
  }
}

const radiusForSize = (size: ButtonSize) => {
  switch (size) {
    case 'min':
      return '2px'
    case 'xs':
      return '2px'
    case 'sm':
      return '4px'
    case 'md':
      return '4px'
    case 'lg':
      return '4px'
  }
}

const backgroundColorForStyle = (style: ButtonStyle) => {
  switch (style) {
    case 'primary':
      return 'var(--button)'
    case 'alt':
      return 'rgba(0,0,0,0)'
    case 'live':
      return 'var(--button-live)'
    case 'on-image':
      return 'var(--button-on-image)'
  }
}

const foregroundColorForStyle = (style: ButtonStyle) => {
  switch (style) {
    case 'primary':
      return 'var(--on-button)'
    case 'alt':
      return 'var(--on-button-alt)'
    case 'live':
      return 'var(--on-button-live)'
    case 'on-image':
      return 'var(--on-button-on-image)'
  }
}

const backgroundHoverColorForStyle = (style: ButtonStyle) => {
  switch (style) {
    case 'primary':
      return 'var(--button-hover)'
    case 'alt':
      return 'var(--button-alt-hover)'
    case 'live':
      return 'var(--button-live-hover)'
    case 'on-image':
      return 'var(--button-on-image-hover)'
  }
}

const backgroundPressedColorForStyle = (style: ButtonStyle) => {
  switch (style) {
    case 'primary':
      return 'var(--button-pressed)'
    case 'alt':
      return `var(--button-alt-pressed)`
    case 'live':
      return `var(--button-live-pressed)`
    case 'on-image':
      return 'var(--button-on-image-pressed)'
  }
}

const backgroundDisabledColorForStyle = (style: ButtonStyle) => {
  switch (style) {
    case 'alt':
      return `none`
    case 'primary':
    case 'live':
    case 'on-image':
      return 'var(--disabled)'
  }
}

const borderDisabledColorForStyle = (style: ButtonStyle) => {
  switch (style) {
    case 'alt':
      return '1px solid var(--disabled)'
    case 'primary':
    case 'live':
    case 'on-image':
      return 'none'
  }
}
const foregroundDisabledColorForStyle = (style: ButtonStyle) => {
  switch (style) {
    case 'alt':
      return 'var(--disabled)'
    case 'primary':
    case 'live':
    case 'on-image':
      return 'var(--on-light-alt)'
  }
}
const borderForStyle = (style: ButtonStyle) => {
  switch (style) {
    case 'primary':
      return 'none'
    case 'alt':
      return '1px solid var(--button-alt)'
    case 'live':
      return 'none'
    case 'on-image':
      return 'none'
  }
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonSize: ButtonSize
  buttonStyle: ButtonStyle
  loading?: boolean
}

const GrandstandButtonStyle = styled.button<ButtonProps>`
  border-radius: ${({ buttonSize }) => radiusForSize(buttonSize)};
  border: ${({ buttonStyle }) => borderForStyle(buttonStyle)};
  color: ${({ buttonStyle }) => foregroundColorForStyle(buttonStyle)};
  background-color: ${({ buttonStyle }) => backgroundColorForStyle(buttonStyle)};
  cursor: pointer;
  padding: ${({ buttonSize }) => paddingForSize(buttonSize)};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  font-size: ${({ buttonSize }) => `var(--button-${buttonSize}-size)`};
  line-height: var(--button-line-height);
  font-weight: var(--button-font-weight);
  font-family: var(--button-font-fam);
  &:hover {
    background-color: ${({ buttonStyle }) => backgroundHoverColorForStyle(buttonStyle)};
  }
  &:active {
    background-color: ${({ buttonStyle }) => backgroundPressedColorForStyle(buttonStyle)};
  }
  &:disabled {
    border: ${({ buttonStyle }) => borderDisabledColorForStyle(buttonStyle)};
    color: ${({ buttonStyle }) => foregroundDisabledColorForStyle(buttonStyle)};
    background-color: ${({ buttonStyle }) => backgroundDisabledColorForStyle(buttonStyle)};
    cursor: default;
    opacity: 0.8;
  }
  & svg {
    // TODO(tgrow) Generalize svg styles so they are applicable to any svg child, not just loading svgs
    width: 24px;
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    ${(props) => props.loading && `animation: rotate 1.2s infinite;`}
  }
  & svg > path,
  & svg > circle {
    color: ${({ buttonStyle }) => foregroundColorForStyle(buttonStyle)};
    stroke: ${({ buttonStyle }) => foregroundColorForStyle(buttonStyle)};
    fill: ${(props) => (props.loading ? 'rgba(0, 0, 0, 0)' : foregroundColorForStyle(props.buttonStyle))};
  }
  div {
    margin: 0 auto;
  }
`

export const GrandstandButton = (props: ButtonProps) => {
  const loadingSpinner = () => {
    return <GrandstandIcon icon="loading" />
  }
  let content
  if (props.loading) {
    content = loadingSpinner()
  } else {
    content = props.children
  }

  const buttonProps = {
    ...props,
    disabled: props.loading ?? props.disabled ?? false,
  }
  return (
    <GrandstandButtonStyle className="grandstand" {...buttonProps}>
      {content}
    </GrandstandButtonStyle>
  )
}
