export type Breakpoint = [number, number]

export type BreakpointKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'mobile' | 'tablet' | 'desktop'

export type HelperBreakpoint = BreakpointKey | Breakpoint

type BreakpointDict = {
  [key in BreakpointKey]: Breakpoint
}

type BreakpointHelpers = {
  up: (breakpoint: HelperBreakpoint) => string
  down: (breakpoint: HelperBreakpoint) => string
  only: (breakpoint: HelperBreakpoint) => string
  between: (from: HelperBreakpoint, to: HelperBreakpoint) => string
}

const breakpointDict: BreakpointDict = {
  xs: [0, 479],
  sm: [480, 767],
  md: [768, 1279],
  lg: [1280, 1439],
  xl: [1440, Infinity],
  mobile: [0, 479],
  tablet: [480, 1279],
  desktop: [1280, Infinity],
}

const getBreakpoint = (arg: HelperBreakpoint): Breakpoint => {
  const toValue = (value: number): number => Math.max(0, Math.min(999999, value))
  const breakpoint = (Array.isArray(arg) ? arg : breakpointDict[arg]) as Breakpoint
  return breakpoint.map((v) => toValue(v)) as Breakpoint
}
const getBreakpointRange = (from: HelperBreakpoint, to: HelperBreakpoint) => {
  const [aMin, aMax] = getBreakpoint(from)
  const [bMin, bMax] = getBreakpoint(to)
  const minWidth = Math.min(aMin, bMin)
  const maxWidth = Math.max(aMax, bMax)
  const breakpoint: Breakpoint = [minWidth, maxWidth]
  return breakpoint
}

const mediaUp = (breakpoint: HelperBreakpoint) => {
  const [minWidth] = getBreakpoint(breakpoint)
  return `@media (min-width: ${minWidth}px)`
}
const mediaDown = (breakpoint: HelperBreakpoint) => {
  const [_, maxWidth] = getBreakpoint(breakpoint)
  return `@media (max-width: ${maxWidth}px)`
}
const mediaOnly = (breakpoint: HelperBreakpoint) => {
  const [minWidth, maxWidth] = getBreakpoint(breakpoint)
  return `@media (max-width: ${maxWidth}px) and (min-width: ${minWidth}px)`
}
const mediaBetween = (from: HelperBreakpoint, to: HelperBreakpoint) => {
  const [minWidth, maxWidth] = getBreakpointRange(from, to)
  return `@media (max-width: ${maxWidth}px) and (min-width: ${minWidth}px)`
}

export type Breakpoints = BreakpointDict & BreakpointHelpers

export const breakpoints: Breakpoints = {
  up: mediaUp,
  down: mediaDown,
  only: mediaOnly,
  between: mediaBetween,
  ...breakpointDict,
}
