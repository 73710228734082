import { useLocalStorage } from '@grandstand-web/bally-web-shared/src/hooks/useLocalStorage'
import { useState } from 'react'
import { ConfigServiceContext, useContextUnconditionally } from '../services/config/ConfigService'

export type ContinuousPlayEnabledType = {
  isWebContinuousPlayEnabled: boolean
  isConnectedWebContinuousPlayEnabled: boolean
}

export const useContinuousPlayEnabled = (): ContinuousPlayEnabledType => {
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const [shouldContinuousPlayLocally] = useLocalStorage('featureFlag_continuous_play', false)
  const [isWebContinuousPlayEnabled, setIsWebContinuousPlayEnabled] = useState(true)
  const [isConnectedWebContinuousPlayEnabled, setIsConnectedWebContinuousPlayEnabled] = useState(false)

  if (
    currentConfig.API.features.continious_play_config.enabled === false &&
    shouldContinuousPlayLocally !== true &&
    isWebContinuousPlayEnabled === true
  ) {
    setIsWebContinuousPlayEnabled(false)
  }

  if (
    (currentConfig.API.features.continious_play_config.enabled === true || shouldContinuousPlayLocally === true) &&
    isConnectedWebContinuousPlayEnabled === false
  ) {
    setIsConnectedWebContinuousPlayEnabled(true)
  }

  return {
    isWebContinuousPlayEnabled,
    isConnectedWebContinuousPlayEnabled,
  }
}
