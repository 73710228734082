import styled from 'styled-components'

const Spinner = styled.div<{ size?: string }>`
  border: 10px solid var(--surface);
  border-top: 10px solid var(--divider-canvas);
  border-radius: 50%;
  width: ${(props) => (props.size ? props.size : '80px')};
  height: ${(props) => (props.size ? props.size : '80px')};
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
export default Spinner
