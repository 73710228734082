import { useRouter } from 'next/router'
import { ReactNode, useCallback, useEffect, useRef } from 'react'
import shaka from 'shaka-player/dist/shaka-player.ui'
import { useContinuousPlayEnabled } from '../hooks/useContinuousPlayEnabled'
import { ErrorDetails, useVideoService } from '../hooks/useVideoService'
import { isConnectedWeb } from '../utils/appUtils'
import { isNotInProd } from '../utils/envUtils'
import { getWindowDeviceInfo } from '../utils/getWindowDeviceInfo'
import { Logger } from '../utils/logger'
import NextIdDisplay from './NextIdDisplay'
import CommonVideoErrorView from './VideoErrorView'
import VideoLoadingOrBuffering from './VideoLoadingOrBuffering'

const videoPlayerLogger = Logger.of('VideoPlayer')

type VideoPlayerProps = {
  videoId: string
  videoStartTime?: number
  muted?: boolean
  chromeless?: boolean
  shouldRenderErrorOverlay?: boolean
  disableLoadingSpinner?: boolean // Used for Hero (WatchVideoRail.tsx), which should not have the loading spinner
  useOwnBufferSpinner?: boolean // Used for CW, because if children are passed to shaka then its own buffering spinner is missing
  shouldTrackVideoAnalytics?: boolean
  disabled?: boolean
  children?: ReactNode
  image?: string
  start?: Date
  onPlayerCanPlayThrough?: () => void
  showLoadingUntilPlaying?: boolean
  VideoErrorView?: ({ error }: { error: ErrorDetails }) => JSX.Element
}

const VideoPlayer = ({ useOwnBufferSpinner, disableLoadingSpinner, ...props }: VideoPlayerProps) => {
  const { ShakaPlayer } = require('./ShakaPlayer')
  const router = useRouter()
  const { error, src } = useVideoService({ videoId: props.videoId, useRouter, isDisabled: props.disabled })
  const { isWebContinuousPlayEnabled, isConnectedWebContinuousPlayEnabled } = useContinuousPlayEnabled()
  const SelectedVideoErrorView = props.VideoErrorView ?? CommonVideoErrorView
  const videoSpinnerRef = useRef()

  useEffect(() => {
    videoPlayerLogger.debug('src changed', { src })
  }, [src])

  const rawVideoResponse = src?.raw
  const drmLicenseUrl = rawVideoResponse?.drm?.licenseUrl
  const drmToken = rawVideoResponse?.drm?.token
  const deviceInfo = getWindowDeviceInfo()

  const handleOnBufferingStart = useCallback(() => {
    if (useOwnBufferSpinner) {
      // @ts-ignore
      videoSpinnerRef?.current?.setIsBuffering(true)
    }
  }, [useOwnBufferSpinner])

  const handleOnBufferingEnd = useCallback(() => {
    // @ts-ignore
    videoSpinnerRef?.current?.setIsBuffering(false)
    // @ts-ignore
    videoSpinnerRef?.current?.declareVideoLoaded()
  }, [])

  const handleOnPlay = useCallback(() => {
    // @ts-ignore
    videoSpinnerRef?.current?.declareVideoLoaded()
  }, [])

  const handleOnPlayerLoaded = useCallback(() => {}, [])

  const handleVideoPlaybackEnded = useCallback(() => {
    if (src?.raw.next_id) {
      const connectedWeb = isConnectedWeb()
      const nextVideoBasePath = connectedWeb ? 'video' : 'game-details'
      if ((connectedWeb && isConnectedWebContinuousPlayEnabled) || (!connectedWeb && isWebContinuousPlayEnabled)) {
        router.push(`/${nextVideoBasePath}/${src?.raw.next_id}`)
      }
    } else {
      // Back to home
      router.push('/')
    }
  }, [isConnectedWebContinuousPlayEnabled, router, src?.raw.next_id, isWebContinuousPlayEnabled])

  const getConfigDrm = () => {
    if (!drmLicenseUrl || !drmToken) {
      // there is no drm to configure
      return undefined
    }

    // fairplay
    if (deviceInfo.browser === 'safari') {
      return {
        servers: { 'com.apple.fps': drmLicenseUrl ?? '' },
        advanced: {
          'com.apple.fps': {
            serverCertificateUri: drmLicenseUrl ? `${drmLicenseUrl}cert/ballysports` : undefined,
          } as shaka.extern.AdvancedDrmConfiguration,
        },
      } as unknown as shaka.extern.DrmConfiguration
    }

    // playready
    if (deviceInfo.platform === 'tv_xboxone') {
      return {
        servers: {
          'com.microsoft.playready': drmLicenseUrl,
        },
      }
    }

    // widevine
    return {
      servers: { 'com.widevine.alpha': drmLicenseUrl ?? '' },
    } as unknown as shaka.extern.DrmConfiguration
  }

  const config: any = {
    manifest: {
      segmentRelativeVttTiming: true,
    },
    drm: getConfigDrm(),
  }

  videoPlayerLogger.debug('shaka config', { src, config })

  if (error) {
    const shouldRenderErrorOverlay = props.shouldRenderErrorOverlay ?? true
    return shouldRenderErrorOverlay ? <SelectedVideoErrorView error={error} /> : <></>
  }

  return (
    <>
      {src?.src && (
        <ShakaPlayer
          drmToken={drmToken}
          config={config}
          src={src}
          autoPlay={props.disabled ? false : true}
          chromeless={props.chromeless ?? false}
          muted={props.muted ?? false}
          onPlayerCanPlayThrough={props.onPlayerCanPlayThrough}
          shouldTrackVideoAnalytics={props.shouldTrackVideoAnalytics}
          disabled={props.disabled ?? false}
          startTime={props.videoStartTime}
          start={props.start}
          poster={props.image}
          onPlayerLoaded={handleOnPlayerLoaded}
          onPlay={handleOnPlay}
          onPlaybackEnded={handleVideoPlaybackEnded}
          onBufferingStart={handleOnBufferingStart}
          onBufferingEnd={handleOnBufferingEnd}
        >
          {props.children}
        </ShakaPlayer>
      )}
      <VideoLoadingOrBuffering ref={videoSpinnerRef} disableLoadingSpinner={disableLoadingSpinner} />
      {isNotInProd() && <NextIdDisplay nextId={src?.raw.next_id} />}
    </>
  )
}

export default VideoPlayer
